export function useSwapShow() {
  const { config } = useAppState()
  const { account, isConnected } = tronComp.useAccountCurrent()
  // 是否开启闪兑功能
  const enableExchange = computed(() => !!config?.value?.exchange?.enable_exchange ?? undefined)
  // 是否开启购买功能
  const isItEnabledPre = computed(() => !!config.value?.exchange.enable_buy_trx)
  // 钱包钱不够13.3或是未激活
  const isNotActivated = computed(() => {
    return toValue(isConnected) && (!toValue(account).balanceTrx || ('balanceTrx' in toValue(account) && (toValue(account).balanceTrx / 1000000) < 13.3))
  })
  // 未激活钱包
  const notActivated = computed(() => {
    return toValue(isConnected) && !toValue(account).balanceTrx
  })
  return {
    enableExchange,
    isNotActivated,
    isItEnabledPre,
    notActivated,
  }
}
